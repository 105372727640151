
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
var flatten = require("flat");

@Component
export default class PropertySearch extends Vue {
    @Getter("activity/viewing") activity!: Activity;
    @Getter("property/viewing") property!: Property;

    loading = false;

    search = "";

    map: { [key: string]: string } = {
        Grondoppervlakte: "characteristics.ground_area",
        "Bewoonbare oppervlakte": "characteristics.livable_area",
        "Kadastraal inkomen": "characteristics.cadastral_income",
        Bouwjaar: "characteristics.building.construction_year",
        renovatiejaar: "characteristics.building.renovation_year",
    };

    type_map: { [key: string]: string } = {
        Grondoppervlakte: "surface",
        "Bewoonbare oppervlakte": "surface",
        "Kadastraal inkomen": "currency",
    };

    flat_map: { [key: string]: any } = {};

    async mounted() {
        this.flat_map = flatten(this.property);
    }

    get results(): Array<{ label: string; value: string; type: string }> {
        if (!this.search) {
            return [];
        }

        const matches = Object.keys(this.map).filter((k: string) => k.toLowerCase().includes(this.search.toLowerCase()));
        const results: Array<{ label: string; value: string; type: string  }> = [];
        matches.forEach((m: string) => {
            if (this.flat_map[this.map[m]] !== null) {
                results.push({ label: m, value: this.flat_map[this.map[m]], type: this.type_map[m] });
            }
        });

        return results;
    }
}
